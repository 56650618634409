import { Turbo } from "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";

const Stimulus = Application.start();
window.Turbo = Turbo;
window.Stimulus = Stimulus;

Turbo.StreamActions.reload_frame = function () {
  const frame = document.getElementById(this.getAttribute("turbo_frame_id"));

  frame.reload();
};

Turbo.StreamActions.redirect_to = function () {
  const url = this.getAttribute("url");

  Turbo.visit(url);
};

import { Autocomplete } from "stimulus-autocomplete";
import {
  Modal,
  Toggle,
  Dropdown,
  Tabs,
  Alert,
} from "tailwindcss-stimulus-components";

/**
 * CUSTOM CONTROLLERS
 */
import AddQueryStringController from "../controllers/v2/add_query_string_controller.js";
import BoxAlertController from "../controllers/box_alert_controller";
import Calendar from "../controllers/calendar_controller";
import MentalHealthController from "../controllers/mental_health_controller";
import CalendarContentLoaderController from "../controllers/calendar_content_loader_controller";
import CheckboxBulkController from "../controllers/checkbox_bulk_controller.js";
import ContentLoaderController from "../controllers/content_loader_controller.js";
import CurrencyFormatterController from "../controllers/currency_formatter_controller.js";
import DateAndPartnerForCalendarController from "../controllers/date_and_partner_for_calendar_controller.js";
import DateForCalendarController from "../controllers/date_for_calendar_controller.js";
import DropDownV2 from "../controllers/v2/dropdown_controller";
import HubspotWidget from "../controllers/v2/hubspot_widget_controller";
import LoadHubspotWidget from "../controllers/v2/load_hubspot_widget_controller";
import FiltersController from "../controllers/filters_controller.js";
import FilterSidebarController from "../controllers/filter_sidebar_controller.js";
import FlashMessage from "../controllers/v2/flash_message_controller";
import InputLenghtTogglerController from "../controllers/input_lenght_toggler_controller.js";
import LitepickerController from "../controllers/litepicker_controller.js";
import MinLengthController from "../controllers/min_length_controller.js";
import PartnerCalendarContentLoaderController from "../controllers/partner_calendar_content_loader_controller.js";
import PhoneNumberFormatter from "../controllers/phone_number_formatter";
import PoolingController from "../controllers/pooling_controller.js";
import PopperController from "../controllers/popper_controller.js";
import PreviewController from "../controllers/preview_controller.js";
import PreviewFilesController from "../controllers/preview_files_controller";
import RedirectController from "../controllers/redirect_controller.js";
import RutInputController from "../controllers/rut_input_controller.js";
import FolioInputFormatterController from "../controllers/folio_formatter_controller.js";
import FonasaBonusDisclaimerController from "../controllers/fonasa_bonus_disclaimer_controller.js";
import ScrollToElementController from "../controllers/v2/scroll_to_element_controller";
import RemoteSearchController from "../controllers/v2/remote_search_controller";
import ShareController from "../controllers/share_controller.js";
import SidebarController from "../controllers/sidebar_controller.js";
import TimeInputFormatterController from "../controllers/time_input_formatter_controller.js";
import ToastController from "../controllers/toast_controller";
import TomSelectController from "../controllers/v2/tom_select_controller";
import TurboModalController from "../controllers/turbo_modal_controller";
import UppercaseController from "../controllers/uppercase_controller";
import PromoCodeFromatterController from "../controllers/promo_code_formatter_controller";
import ClickController from "../controllers/v2/click_controller";
import TooltipController from "../controllers/v2/tooltip_controller";
import AutosaveController from "../controllers/autosave_controller.js";
import PasswordController from "../controllers/password_controller";
import ChangePasswordController from "../controllers/change_password_controller";
import VideocallSetup from "../controllers/videocall_setup_controller.js";
import FullscreenController from "../controllers/v2/fullscreen_controller";
import EnableSubmit from "../controllers/v2/enable_submit_controller";
import SelectMessages from "../controllers/v2/select_messages_controller";
import TabsController from "../controllers/v2/tabs_controller.js";
import TextOverflow from "../controllers/text_overflow_controller.js";
import TurboDelay from "../controllers/v2/turbo_delay_controller";
import TurboLinkTo from "../controllers/turbo_link_to_controller";
import CarouselController from "../controllers/carousel_controller.js";
import StickyDaypickerController from "../controllers/sticky_daypicker_controller.js";
import TrackClickController from "../controllers/track_click_controller.js";
import ReadMoreController from "../controllers/read_more_controller.js";
import CalendarOverlayController from "../controllers/calendar_overlay_controller.js";
import MultiplePopUpsController from "../controllers/multiple_pop_ups_controller.js";
import SideModalController from "../controllers/side_modal_controller.js";
import NavbarController from "../controllers/navbar_controller.js";
import HangUpImmeetController from "../controllers/hang_up_immeet_controller.js";
import ShowToastController from "../controllers/show_toast_controller.js";
import PartnerMeetingRoomController from "../controllers/partner_meeting_room_controller.js";
import SwitchLinkToController from "../controllers/switch_link_to_controller.js";
import ProvisionWarnings from "../controllers/provision_warning_controller.js";
import BrowserNotificationsController from "../controllers/browser_notifications_controller.js";
import FileUploadController from "../controllers/file_upload_controller.js";
import SendBrowserNotificationController from "../controllers/send_browser_notification_controller.js";
import DragAndDropController from "../controllers/drag_and_drop_controller.js";
import DocumentNumberController from "../controllers/document_number_input_controller.js";
import PinController from "../controllers/pin_controller.js";
import TimelineEventController from "../controllers/timeline_event_controller.js"

Stimulus.register("pin", PinController);
Stimulus.register("document-number-input", DocumentNumberController);
import DynamicCurrencyFormatter from "../controllers/dynamic_currency_formatter.js";
import PrescriptionsController from "../controllers/prescriptions_controller.js";

Stimulus.register("prescriptions", PrescriptionsController);
Stimulus.register("dynamic-currency-formatter", DynamicCurrencyFormatter);
Stimulus.register("drag-and-drop", DragAndDropController);
Stimulus.register(
  "send-browser-notification",
  SendBrowserNotificationController
);
Stimulus.register("browser-notifications", BrowserNotificationsController);
Stimulus.register("provision-warning", ProvisionWarnings);
Stimulus.register("partner-meeting-room", PartnerMeetingRoomController);
Stimulus.register("side-modal", SideModalController);
Stimulus.register("switch-link-to", SwitchLinkToController);
Stimulus.register("multiple-pop-ups", MultiplePopUpsController);
Stimulus.register("calendar-overlay", CalendarOverlayController);
Stimulus.register("mental-health", MentalHealthController);
Stimulus.register("hang-up-immeet", HangUpImmeetController);
Stimulus.register("navbar", NavbarController);
Stimulus.register("show-toast", ShowToastController);
Stimulus.register("sticky-daypicker", StickyDaypickerController);
Stimulus.register("track-click", TrackClickController);
Stimulus.register("read-more", ReadMoreController);
Stimulus.register("carousel", CarouselController);
Stimulus.register("text-overflow", TextOverflow);
Stimulus.register("turbo-tabs", TabsController);
Stimulus.register("turbo-link-to", TurboLinkTo);
Stimulus.register("fullscreen", FullscreenController);
Stimulus.register("alert", Alert); // TODO: this should be deprecated in favor of ToastController
Stimulus.register("add-query-string", AddQueryStringController);
Stimulus.register("autocomplete", Autocomplete);
Stimulus.register("box-alert", BoxAlertController);
Stimulus.register("calendar-content-loader", CalendarContentLoaderController);
Stimulus.register("calendar", Calendar);
Stimulus.register("checkbox-bulk", CheckboxBulkController);
Stimulus.register("content-loader", ContentLoaderController);
Stimulus.register("currency-formatter", CurrencyFormatterController);
Stimulus.register(
  "date-and-partner-for-calendar",
  DateAndPartnerForCalendarController
);
Stimulus.register("date-for-calendar", DateForCalendarController);
Stimulus.register("dropdown-v2", DropDownV2);
Stimulus.register("dropdown", Dropdown);
Stimulus.register("filter-sidebar", FilterSidebarController);
Stimulus.register("filters", FiltersController);
Stimulus.register("flash-message", FlashMessage);
Stimulus.register("input-lenght-toggler", InputLenghtTogglerController);
Stimulus.register("litepicker", LitepickerController);
Stimulus.register("min-length", MinLengthController);
Stimulus.register("modal", Modal);
Stimulus.register(
  "partner-calendar-content-loader",
  PartnerCalendarContentLoaderController
);
Stimulus.register("phone-number-formatter", PhoneNumberFormatter);
Stimulus.register("pooling", PoolingController);
Stimulus.register("popper", PopperController);
Stimulus.register("preview-files", PreviewFilesController);
Stimulus.register("preview", PreviewController);
Stimulus.register("redirect", RedirectController);
Stimulus.register("rut-input", RutInputController);
Stimulus.register("scroll-to-element", ScrollToElementController);
Stimulus.register("remote-search", RemoteSearchController);
Stimulus.register("share", ShareController);
Stimulus.register("sidebar", SidebarController);
Stimulus.register("tabs", Tabs);
Stimulus.register("time-input-formatter", TimeInputFormatterController);
Stimulus.register("folio-formatter", FolioInputFormatterController);
Stimulus.register("fonasa-bonus-disclaimer", FonasaBonusDisclaimerController);
Stimulus.register("toast", ToastController);
Stimulus.register("toggle", Toggle);
Stimulus.register("tom-select", TomSelectController);
Stimulus.register("turbo-modal", TurboModalController);
Stimulus.register("uppercase", UppercaseController);
Stimulus.register("promo-code-formatter", PromoCodeFromatterController);
Stimulus.register("click", ClickController);
Stimulus.register("tooltip", TooltipController);
Stimulus.register("autosave", AutosaveController);
Stimulus.register("password", PasswordController);
Stimulus.register("change-password", ChangePasswordController);
Stimulus.register("setup-videocall", VideocallSetup);
Stimulus.register("hubspot-widget", HubspotWidget);
Stimulus.register("load-hubspot-widget", LoadHubspotWidget);
Stimulus.register("enable-submit", EnableSubmit);
Stimulus.register("select-messages", SelectMessages);
Stimulus.register("turbo-delay", TurboDelay);
Stimulus.register("file-upload", FileUploadController);
Stimulus.register("timeline-event", TimelineEventController)

import LocalTime from "local-time";

LocalTime.config.i18n["es"] = {
  date: {
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    abbrDayNames: ["dom", "lun", "mar", "mie", "jue", "vie", "sab"],
    abbrMonthNames: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    yesterday: "ayer",
    today: "hoy",
    tomorrow: "mañana",
    on: "el {date}",
    formats: {
      default: "%e %b, %Y",
      thisYear: "%e %b",
    },
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "un {time}",
    singularAn: "un {time}",
    elapsed: "hace {time}",
    second: "segundo",
    seconds: "segundos",
    minute: "minutos",
    minutes: "minutos",
    hour: "hora",
    hours: "horas",
    formats: {
      default: "%l:%M%P",
    },
  },
  datetime: {
    at: "{date} a las {time}",
    formats: {
      default: "%B %e, %Y a las %l:%M%P %Z",
      default_24h: "%B %e, %Y a las %H:%M %Z",
    },
  },
};

LocalTime.config.locale = "es";

LocalTime.start();
